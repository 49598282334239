import { Injectable } from '@angular/core'

import { MsalBroadcastService, MsalService } from '@azure/msal-angular'
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser'
import { filter } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class LoginService {
	authenticated: boolean = false

	constructor(private msalService: MsalService, private msalBroadcastService: MsalBroadcastService) {}

	ngOnInit(): void {
		this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)).subscribe((result: EventMessage) => {
			console.log(result)
		})

		this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)).subscribe(() => {
			this.authentication()
		})
	}

	authentication() {
		this.authenticated = this.msalService.instance.getAllAccounts() ? true : false
	}
}
