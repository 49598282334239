import { Usuario } from '../models/usuario.model';
import { TipoAtividade } from './tipoAtividade.model';

export class RegistroAtividade {
  id?: string;
  tipo: TipoAtividade;
  nomeProcesso: string;
  idGrupo: string;
  nomeGrupo: string;
  usuario: Usuario;
  nomeArquivo: string;
  dataHora: Date;
  nomeArquivoEnviado: string;
  _ts?: number;
}
