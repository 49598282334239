import { Component, OnInit } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

import { Mensagem } from 'src/app/models/mensagem.model'
import { environment } from 'src/environments/environment'
import { MensagemService } from 'src/app/shared/mesagem.service'
import { ToastrService } from 'ngx-toastr'
import { Router } from '@angular/router'
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { LoginService } from 'src/app/shared/login.service'

const idMensagem = environment.message.id

//@Pipe({ name: 'safeHtml' })
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
	mensagem: Mensagem
	textoMensagem: SafeHtml
	loginDisplay = false

	modalRef: NgbModalRef

	constructor(
		private mensagemService: MensagemService,
		private sanitized: DomSanitizer,
		private loginService: LoginService,
		private toastrService: ToastrService,
		private router: Router
	) {
		this.getMensagemPorID(idMensagem)
		this.loginService.ngOnInit()
		this.loginDisplay = this.loginService.authenticated
	}

	ngOnInit(): void {}

	getInnerHTMLValue(value: string) {
		this.textoMensagem = this.sanitized.bypassSecurityTrustHtml(value)
	}

	getMensagemPorID(msgID: string) {
		this.mensagemService.getMensagemPorID(msgID).subscribe((res) => {
			if (res.status <= 400) {
				this.showAlertInfo(res.statusText)
			} else {
				this.mensagem = {
					id: res.mensagem.id,
					titulo: res.mensagem.titulo,
					texto: res.mensagem.texto,
				}
				this.getInnerHTMLValue(res.mensagem.texto)
			}
		})
	}

	showAlertInfo(msg: any) {
		this.toastrService.error(`${msg} - Erro ao executar a chamada, por favor tente mais tarde!`, 'PTA - Portal de transferência de arquivo')
		this.router.navigateByUrl('/home')
		this.modalRef.close()
	}
}
