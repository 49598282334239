import { LOCALE_ID, NgModule } from '@angular/core'
import localePt from '@angular/common/locales/pt'
import { DatePipe, registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { NgbDatepickerModule, NgbInputDatepickerConfig, NgbModule, NgbProgressbarModule, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap'
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons'
import { OrderModule } from 'ngx-order-pipe'
import { ToastrModule } from 'ngx-toastr'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'

import { MsalGuard, MsalInterceptor, MsalRedirectComponent, MsalService, MsalModule } from '@azure/msal-angular'
import { InteractionType, PublicClientApplication } from '@azure/msal-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import { NavbarComponent } from './components/navbar/navbar.component'
import { SidebarComponent } from './components/sidebar/sidebar.component'
import { FooterComponent } from './components/footer/footer.component'
import { ContentComponent } from './components/content/content.component'

import { HomeComponent } from './pages/home/home.component'
import { UploadComponent } from './pages/upload/upload.component'
import { ArquivosEnviadosComponent } from './pages/arquivosenviados/arquivosenviados.component'
import { UploadService } from './pages/upload/upload.service'

import { LoaderComponent } from './shared/loader/loader.component'
import { LoaderInterceptor } from './shared/loader/loader.interceptor'
import { LoaderService } from './shared/loader/loader.service'
import { LoginService } from './shared/login.service'

import { msalConfig, protectedResources  } from '../environments/environment'

registerLocaleData(localePt)

@NgModule({ declarations: [
        AppComponent,
        NavbarComponent,
        SidebarComponent,
        ContentComponent,
        FooterComponent,
        HomeComponent,
        LoaderComponent,
        ArquivosEnviadosComponent,
        UploadComponent,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        OrderModule,
        NgbModule,
        NgbProgressbarModule,
        NgxBootstrapIconsModule.pick(allIcons),
        CKEditorModule,
        ToastrModule.forRoot(),
        NgbDatepickerModule,
        MsalModule.forRoot(new PublicClientApplication(msalConfig), {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: ['openid', 'profile'],
            },
        }, {
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map([[protectedResources.endpoint, protectedResources.scopes]]),
        })], providers: [
        NgbInputDatepickerConfig,
        NgbProgressbarConfig,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        LoaderService,
        MsalService,
        MsalGuard,
        LoginService,
        DatePipe,
        UploadService,
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
