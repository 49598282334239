import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { DatePipe } from '@angular/common'
import { Router } from '@angular/router'

import { MsalService } from '@azure/msal-angular'
import { AccountInfo } from '@azure/msal-browser'

import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { OrderPipe } from 'ngx-order-pipe'
import { ToastrService } from 'ngx-toastr'
import * as JSZip from 'jszip'
import { saveAs } from 'file-saver'

import { Arquivo } from 'src/app/models/arquivo.model'
import { Documento } from 'src/app/models/documento.model'
import { RegistroAtividade } from 'src/app/models/registroAtividade.model'
import { TipoAtividade } from 'src/app/models/tipoAtividade.model'
import { Usuario } from 'src/app/models/usuario.model'

import { ListUserService } from 'src/app/shared/listUser.service'
import { MensagemService } from 'src/app/shared/mesagem.service'
import { UsuarioService } from 'src/app/shared/usuario.service'
import { LoginService } from 'src/app/shared/login.service'

import { UploadService } from './upload.service'

@Component({
	selector: 'app-upload',
	templateUrl: './upload.component.html',
	styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
	@ViewChild('fileInput', { static: false }) fileInput: ElementRef

	arquivos: Array<Arquivo> = []
	usuario: Usuario
	registroAtividade: RegistroAtividade
	documento: Documento

	infoMessage = { status: 0, message: '' }

	file: any
	fileUpload = { status: 'initial', message: 0, filePath: '' }
	profileForm: FormGroup
	modalRef: NgbModalRef
	isDisabled: boolean = true
	sucessRequest: boolean = false
	nameInputFile: string = ''
	error: string
	zipFile: JSZip

	// search
	_listFilter: string
	filtered: Array<Arquivo> = []
	filter = new FormControl('')

	// paginação
	page = 1
	pageSize = 50
	collectionSize: number
	pagefiltered: Array<Arquivo> = []
	order: string = 'DataCriacao'
	key: string = 'data'
	reverse: boolean = false
	sort(key) {
		this.key = key
		this.reverse = !this.reverse
	}

	loginDisplay: boolean = false
	profile: AccountInfo[]
	constructor(
		private uploadService: UploadService,
		private msalService: MsalService,
		private listUserService: ListUserService,
		private loginService: LoginService,
		private usuarioService: UsuarioService,
		public messageService: MensagemService,
		private formBuilder: FormBuilder,
		private pipe: DatePipe,
		private orderPipe: OrderPipe,
		private toastrService: ToastrService,
		private router: Router
	) {
		this.loginService.ngOnInit()
		this.loginDisplay = this.loginService.authenticated
		this.orderPipe.transform(this.arquivos, this.order)
		this.orderPipe.transform(this.pagefiltered, this.order)
		this.createProfileForm()
	}

	ngOnInit(): void {
		this.profile = this.msalService.instance.getAllAccounts()
		this.listUserService.listarUser()
		this.messageService.clear()
		this.getUserEmail()
	}

	get listFilter(): string {
		return this._listFilter
	}

	set listFilter(value: string) {
		this.filtered = this.arquivos
		this._listFilter = value
		this.filtered = this.listFilter ? this.onFilter(this.listFilter) : this.arquivos
		this.collectionSize = this.filtered.length
		this.pagefiltered = this.filtered.sort((a, b) => {
			var dataInicial = new Date(a.DataCriacao).getTime() / 1000
			var dataFinal = new Date(b.DataCriacao).getTime() / 1000
			return dataInicial > dataFinal ? -1 : 1
		})
		this.refreshPage()
	}

	onFilter(text: string): Array<Arquivo> {
		return this.arquivos.filter((arquivo) => {
			const term = text.toLowerCase()
			return (
				arquivo.Nome.toLowerCase().includes(term) ||
				// @ts-ignore: Object is possibly 'null'.
				this.pipe.transform(arquivo.DataCriacao, 'dd-MM-yyyy').includes(term)
			)
		})
	}

	refreshPage() {
		this.pagefiltered = this.listFilter
			? this.filtered
					.map((arquivo, i) => ({ index: i + 1, ...arquivo }))
					.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
			: this.arquivos
					.map((arquivo, i) => ({ index: i + 1, ...arquivo }))
					.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
	}

	cleanFiltered() {
		this.collectionSize = this.arquivos.length
		this.listFilter = ''
		this.refreshPage()
	}

	getUserEmail() {
		this.profile = this.msalService.instance.getAllAccounts()
		if (this.profile) {
			this.usuarioService.getEmailUser(this.profile[0].username).subscribe((res) => {
				if (res.status <= 400) {
					this.showAlertInfo(res.statusText)
				} else {
					this.usuario = res.usuario
					this.getFiles()
				}
			})
		}
	}

	getFiles() {
		if (this.usuario.grupo) {
			const empresas = this.usuario.grupo.empresas.map((empresa) => empresa.codigo)
			if (empresas) {
				this.uploadService.getFiles(empresas).subscribe((res) => {
					console.log(res, 'response')
					this.infoMessage.status = 2
					if (res.status == 'NOK') {
						this.showAlertInfo(res.mensagem)
					} else {
						if (res._count == 0 || res.arquivos!.length == 0) {
							this.infoMessage = { status: 1, message: 'Não existem arquivos processados para esse grupo!' }
						} else {
							this.infoMessage.status = 3
							this.arquivos = res.arquivos
							this.listFilter
							this.cleanFiltered()
						}
					}
				})
			}
		}
	}

	nameFileFormatedTable(nomeFile: string) {
		var splitExt = nomeFile.split('.')
		var splitName = splitExt[0].split('_')
		var splitNamePOP = splitName.pop()
		var qt = splitName.length

		if (qt < 3) {
			return nomeFile
		}

		var codigo = splitName[0]
		var anoMes = splitName[qt - 1]
		var extensao = splitExt[1]

		var processos = splitName.slice(1, qt - 1)

		return `${codigo}_${processos.join('_')}_${anoMes}.${extensao}`
	}

	nameFileFormated(file: any) {
		var slip = file.name.split('.')
		var splitName = slip[0].split('_')
		var splitExt = slip[1]
		var qt = splitName.length
		console.log(qt, 'qt')

		if (qt < 3) {
			return file
		}

		var codigo = splitName[0]
		var anoMes = splitName[qt - 1]
		var extensao = splitExt

		var processos = splitName.slice(1, qt - 1)

		file['nome'] = `${codigo}_${processos.join('_')}_${anoMes}.${extensao}`
		file['tipo'] = `${extensao}`
		this.file = file
	}

	onSelectedFile(event) {
		this.messageService.clear()
		this.nameFileFormated(event.target.files[0])

		this.nameInputFile = this.file.nome

		if (this.file.size === 0) {
			this.showErrorCreate('O arquivo está sem conteúdo.')
			this.isDisabled = true
		} else {
			switch (this.file.tipo) {
				case 'txt':
					let files: any
					this.uploadCheckedCaracteres(this.file).then((res) => {
						files = res
						if (files!.name.match(/\d{1,3}\_.+\_(?![a-zA-Z])(\d{1,6})\.txt/)) {
							this.isDisabled = false
							this.profileForm.get('profile')?.setValue(files)
						} else {
							this.showErrorCreate(`O arquivo - ${this.file.nome} está com sua descrição fora do padrão esperado codigo_processo_anoMes`)
							this.resetForm()
						}
					})
					break
				case 'xlsx':
					if (this.file.nome.match(/\d{1,3}\_.+\_(?![a-zA-Z])(\d{1,6})\.xlsx/)) {
						this.isDisabled = false
						this.profileForm.get('profile')?.setValue(this.file)
					} else {
						this.showErrorCreate(`O arquivo - ${this.file.nome}, está com sua descrição fora do padrão esperado codigo_processo_anoMes`)
						this.resetForm()
					}
					break
				case 'xls':
					if (this.file.nome.match(/\d{1,3}\_.+\_(?![a-zA-Z])(\d{1,6})\.xls/)) {
						this.isDisabled = false
						this.profileForm.get('profile')?.setValue(this.file)
					} else {
						this.showErrorCreate(`O arquivo - ${this.file.nome}, está com sua descrição fora do padrão esperado codigo_processo_anoMes`)
						this.resetForm()
					}
					break
				default:
					this.isDisabled = true
					break
			}
		}
	}

	clearInputFile() {
		this.fileInput.nativeElement.value = ''
	}

	resetForm() {
		this.isDisabled = true
		this.nameInputFile = ''
		this.profile = []
		this.profileForm.reset()
		this.clearInputFile()
	}

	createProfileForm() {
		this.profileForm = this.formBuilder.group({
			name: ['', [Validators.required]],
			profile: ['', [Validators.required]],
		})
	}

	onCreate() {
		this.sucessRequest = false
		const formData = new FormData()
		formData.append('name', this.profileForm.get('name')?.value)
		formData.append('profile', this.profileForm.get('profile')?.value)

		const file = this.profileForm.get('profile')?.value
		this.getBase64(file).then((res) => {
			const documentoPart: any[] = file.name.split('_', file.length)
			const nomeArquivo = file.name.substring(file.name.indexOf('_') + 1, file.name.lastIndexOf('_'))
			const codigoEmpresa = this.usuario.grupo.empresas.map((emp) => emp.codigo.toUpperCase().trim())
			const codigoFiltrado = codigoEmpresa.includes(documentoPart[0].toUpperCase())
			const nomeProcesso = this.usuario.grupo.enviarProcessos.map((proc) => proc.nome.toLowerCase().trim())
			const nomeProcessoFiltrado = nomeProcesso.includes(nomeArquivo.toLowerCase().trim())
			this.registroAtividade = new RegistroAtividade()

			if (codigoFiltrado && nomeProcessoFiltrado) {
				this.isDisabled = true
				this.documento = {
					nomeArquivo: file.name,
					data: `${new Date().getDate().toString()}/${new Date().getMonth() + 1}/${new Date().getFullYear().toString()} ${new Date()
						.getHours()
						.toString()}:${new Date().getMinutes().toString()}:${new Date().getSeconds().toString()}`,
					tipo: 'upload',
					processo: nomeArquivo,
					grupo: this.usuario.grupo.nome,
					usuario: this.usuario.nome,
					conteudoBase64: res,
				}
				this.uploadService.enviarDocumento(formData, this.documento).subscribe(
					(res) => {
						this.fileUpload = res
						if (res.name === 'TimeoutError' || res.name === '') {
							this.showErrorCreate(`Erro ao tentar fazer o envio do arquivo! Mensagem: ${res.message}`)
						}
						if (res === 201) {
							this.messageService.clear()
							this.showSucessCreate(`Arquivo ${this.file.nome} enviado com sucesso.`)
							this.resetForm()
							this.ngOnInit()
							this.sucessRequest = true
						}
					},
					(error) => {
						const erro: number = error || error.messsage
						this.showErrorCreate(`Erro ao tentar fazer o envio do arquivo! Mensagem: ${erro}`)
					}
				)
			} else if (codigoFiltrado === false) {
				if (codigoEmpresa.includes(documentoPart[0].toUpperCase().trim() || documentoPart[0].toLowerCase().trim())) {
					this.showErrorCreate(`Erro ao tentar enviar o arquivo, empresa de código: ${documentoPart[0]}, verifique se o nome está maiúsculo.`)
				} else {
					this.showErrorCreate(
						`Empresa de código: ${documentoPart[0]}, não cadastrada para o grupo  deste usuário, as empresas cadastradas são: ${codigoEmpresa}`
					)
				}
			} else if (nomeProcessoFiltrado === false) {
				this.showErrorCreate(`Nome processo: ${nomeArquivo}, Processo não cadastrado para o grupo de empresas deste usuário.`)
			}
		})
	}

	uploadCheckedCaracteres(file): Promise<File> {
		const parsed: string[] = []
		let blob: any = null
		if (file) {
			return new Promise((resolve, reject) => {
				const fileReader = new FileReader()
				fileReader.onload = (e) => {
					const line = fileReader.result!.toString()
					const removeAccent = line!
						.normalize('NFD')
						.replace(/[\u0300-\u036f]/gi, '')
						.toString()
					parsed.push(removeAccent)
					blob = new File(parsed, file!.nome)
					resolve(blob)
				}
				fileReader.readAsText(file, 'ISO-8859-1')
				fileReader.onerror = (error) => reject(error)
			})
		} else {
			return file
		}
	}

	getBase64(file): Promise<string> {
		const doc: any[] = file.name.split('_', file.length)
		if (doc.length >= 3) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.onload = () => {
					const line = reader.result?.toString()
					let encoded = line!.toString().replace(/^data:(.*;base64,)?/, '')
					if (encoded!.length % 4 > 0) encoded += '='.repeat(4 - (encoded!.length % 4))
					resolve(encoded)
				}
				reader.readAsDataURL(file)
				reader.onerror = (error) => reject(error)
			})
		} else {
			return file
		}
	}

	getCompactados(filename, data) {
		this.zipFile.file(filename, data, { binary: true })
	}

	getLogDownload(arquivo: Arquivo) {
		const registroAtividade = new RegistroAtividade()
		this.registroAtividade = {
			tipo: TipoAtividade.download,
			nomeProcesso: arquivo.Nome.substring(arquivo.Nome.indexOf('_') + 1, arquivo.Nome.lastIndexOf('_')),
			idGrupo: this.usuario.grupo.id,
			nomeGrupo: this.usuario.grupo.nome,
			usuario: this.usuario,
			nomeArquivo: arquivo.Nome,
			dataHora: new Date(),
			nomeArquivoEnviado: '',
		}
	}

	download(arquivo: Arquivo) {
		const registroAtividade = new RegistroAtividade()
		this.registroAtividade = {
			tipo: TipoAtividade.download,
			nomeProcesso: arquivo.Nome.substring(arquivo.Nome.indexOf('_') + 1, arquivo.Nome.lastIndexOf('_')),
			idGrupo: this.usuario.grupo.id,
			nomeGrupo: this.usuario.grupo.nome,
			usuario: this.usuario,
			nomeArquivo: arquivo.Nome,
			dataHora: new Date(),
			nomeArquivoEnviado: '',
		}
	}

	downloadZip(event) {
		this.zipFile = new JSZip()
		const filename = 'arquivos.zip'
		const arquivos = this.pagefiltered.map((blobFile) => blobFile)
		let data: string
		this.pagefiltered.forEach((obj) => {
			let xhr = new XMLHttpRequest()
			xhr.open('GET', obj.Url, false)
			xhr.overrideMimeType('application/octet-streamplain; charset=utf-8')
			xhr.onload = function (e) {
				if (this.status === 200) data = this.responseText
			}
			xhr.send()
			this.getCompactados(obj.Nome, data)
			this.getLogDownload(obj)
		})

		this.zipFile.generateAsync({ type: 'blob' }).then(function (file) {
			saveAs(file, filename)
		})
	}

	showSucessCreate(msg: any) {
		this.toastrService.success(msg, 'PTA - Portal de transferência de arquivo', {
			timeOut: 2000,
			closeButton: true,
		})
		this.router.navigateByUrl('/upload')
		this.getFiles()
		this.resetForm()
	}

	showErrorCreate(msg: any) {
		this.toastrService.error(msg, 'PTA - Portal de transferência de arquivo', {
			timeOut: 2000,
			closeButton: true,
		})
		this.router.navigateByUrl('/upload')
		this.getFiles()
		this.resetForm()
	}

	showAlertInfo(msg: any) {
		this.toastrService.error(`${msg} - Erro ao executar a chamada, por favor tente mais tarde!`, 'PTA - Portal de transferência de arquivo', {
			timeOut: 2000,
			closeButton: true,
		})
		this.router.navigateByUrl('/upload')
		this.resetForm()
	}
}
