import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'

const HOST = environment.HOST
const PATH_PTA = environment.PATH_PTA
const PATH_COSMOS = environment.PATH_COSMOS

const headers = environment.HttpHeaders
const httpOptions = { headers }

@Injectable({
	providedIn: 'root',
})
export class ArquivosEnviadosService {
	constructor(private http: HttpClient) {}

	getRegistroAtividade(): Observable<any> {
		return this.http.get<any>(`${HOST}/${PATH_PTA}/registroatividades`, httpOptions).pipe(
			tap((_) => console.log(`Response getRegistroAtividade`)),
			catchError(this.handleError<any>('getRegistroAtividade', null))
		)
	}

	getRegistroAtividadePorUsuario(query: any): Observable<any> {
		return this.http.post<any>(`${HOST}/${PATH_COSMOS}/pta/colecoes/registro-atividades`, JSON.stringify(query), httpOptions).pipe(
			tap((_) => console.log(`Response getRegistroAtividadePorUsuario`)),
			catchError(this.handleError<any>('getRegistroAtividadePorUsuario', null))
		)
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error) // log to console instead
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO ERRO: ${error.error}`)
				console.error('RETORNO ERROR ERROR MESSAGE:', error.error.message)
			}
			return of(error)
		}
	}
}
