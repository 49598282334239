import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

const HOST = environment.HOST
const PATH_PTA = environment.PATH_PTA

const headers = environment.HttpHeaders
const httpOptions = { headers }
@Injectable({
	providedIn: 'root',
})
export class UsuarioService {
	constructor(private http: HttpClient) {}

	getEmailUser(emailUser: string): Observable<any> {
		if (!emailUser.trim()) {
			return of(`${emailUser} não localizado`)
		}
		return this.http.get<any>(`${HOST}/${PATH_PTA}/usuario?email=${emailUser}`, httpOptions).pipe(
			tap((_) => console.log(`Response getEmailUser`)),
			catchError(this.handleError<any>('getEmailUser', null))
		)
	}

	getUsuarios(): Observable<any> {
		return this.http.get<any>(`${HOST}/${PATH_PTA}/usuarios`, httpOptions).pipe(
			tap((_) => console.log(`Response getUsuarios`)),
			catchError(this.handleError<any>('getUsuarios', null))
		)
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error) // log to console instead
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO ERRO: ${error.error}`)
				console.error('RETORNO ERROR ERROR MESSAGE:', error.error.message)
			}
			return of(error)
		}
	}
}
