import { Configuration, LogLevel, BrowserCacheLocation } from '@azure/msal-browser'

export const environment = {
  production: false,
  descricaoAmbiente: 'Desenvolvimento',

  HOST: 'https://azbrsp1apih001.azure-api.net',
	PATH_PTA: 'pta/v1',
	PATH_BLOB: 'blob/v1',
	PATH_EMAIL: 'email/v1',
	PATH_COSMOS: 'docs-bd/v1/bds',
	PATH_COSMOS_SDK: 'docs-bd/v1/consultas/bds',

  message: {
    id: 'b1103bc5-e87d-4daf-bc8e-b613d504c1ea',
  },

  HttpHeaders: {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': '30b0c10d458c42688d141d5446313f51',
  },

};


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1

export const b2cPolicies = {
	names: {
		//signUpSignIn: 'B2C_1_PTA_signin_logon_MFA',
		signUpSignIn: 'B2C_1_PTA_signin_logon_reset',
		editProfile: 'B2C_1_PTA_editprofile',
	},
	authorities: {
		signUpSignIn: {
			authority:
				//'https://valia.b2clogin.com/valia.onmicrosoft.com/B2C_1_PTA_signin_logon_MFA'
				'https://valia.b2clogin.com/valia.onmicrosoft.com/B2C_1_PTA_signin_logon_reset',
		},
		editProfile: {
			authority: 'https://valia.b2clogin.com/valia.onmicrosoft.com/B2C_1_PTA_editprofile',
		},
	},
	authorityDomain: 'valia.b2clogin.com',
}

export const msalConfig: Configuration = {
	auth: {
		clientId: 'a935d32b-78d6-40c6-aaf3-3ae6cc175bad',
		authority: b2cPolicies.authorities.signUpSignIn.authority,
		knownAuthorities: [b2cPolicies.authorityDomain],
		redirectUri: 'https://ptapublicador-dev.valia.com.br/home',
    	//redirectUri: 'http://localhost:4200/home',

		postLogoutRedirectUri: window.location.origin + '/',
	},
	cache: {
		cacheLocation: BrowserCacheLocation.SessionStorage,
		storeAuthStateInCookie: isIE,
	},
	system: {
		loggerOptions: {
			loggerCallback: (logLevel, message, containsPii) => {
				console.log(message)
			},
			logLevel: LogLevel.Verbose,
			piiLoggingEnabled: false,
		},
	},
}

export const protectedResources = {
	endpoint: 'https://valia.azurewebsites.net/hello',
	scopes: ['https://valia.onmicrosoft.com/helloapi/demo.read'],
}

export const loginRequest = {
	scopes: [],
}