import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { MsalGuard } from '@azure/msal-angular'

import { HomeComponent } from './pages/home/home.component'
import { ArquivosEnviadosComponent } from './pages/arquivosenviados/arquivosenviados.component'
import { UploadComponent } from './pages/upload/upload.component'

const routes: Routes = [
	{
		path: '',
		component: HomeComponent,
		canActivate: [MsalGuard],
	},
	{
		path: 'home',
		component: HomeComponent,
		canActivate: [MsalGuard],
	},
	{
		path: 'upload',
		component: UploadComponent,
		canActivate: [MsalGuard],
	},
	{
		path: 'arquivosenviados',
		component: ArquivosEnviadosComponent,
		canActivate: [MsalGuard],
	},
]

const isIframe = window !== window.parent && !window.opener

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabledNonBlocking',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
