import { OnInit, Injectable, EventEmitter } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { MsalService } from '@azure/msal-angular'
import { Router } from '@angular/router'

import { environment } from 'src/environments/environment'

const HOST = environment.HOST
const PATH_PTA = environment.PATH_PTA

const headers = environment.HttpHeaders
const httpOptions = { headers }

@Injectable({
	providedIn: 'root',
})
export class ListUserService implements OnInit {
	mostrarMenuEmitterStatusUser = new EventEmitter<boolean>()

	constructor(private http: HttpClient, private router: Router, private msalService: MsalService) {}

	ngOnInit() {}

	listarUser() {
		this.http.get<any>(`${HOST}/${PATH_PTA}/usuario?email=${this.msalService.instance.getAllAccounts()[0].username}`, httpOptions).subscribe((resultado) => {
			if (!resultado.usuario.email || resultado.usuario.status == 'inativo') {
				this.router.navigate(['/logout'])
				this.mostrarMenuEmitterStatusUser.emit(false)
			} else {
				this.mostrarMenuEmitterStatusUser.emit(true)
			}
		})
	}
}
