<div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
  <a [routerLink]="['/home']" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
    <em i-bs name="list" width="30" height="30"></em>
  </a>
  <ul *ngIf="loginDisplay" class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
    <li class="nav-item">
      <a [routerLink]="['/home']" class="nav-link align-middle px-0" [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }">
        <em i-bs name="house-fill" width="20" height="20"></em> <span class="ms-1 d-none d-sm-inline">Início</span>
      </a>
    </li>
    <li class="nav-item">
      <a [routerLink]="['/upload']" class="nav-link align-middle px-0" [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }">
        <em i-bs name="file-earmark-arrow-up-fill" width="20" height="20"></em> <span class="ms-1 d-none d-sm-inline">Enviar/Receber Arquivos</span>
      </a>
    </li>
    <li class="nav-item">
      <a [routerLink]="['/arquivosenviados']" class="nav-link align-middle px-0" [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }">
        <em i-bs name="file-earmark-text-fill" width="20" height="20"></em> <span class="ms-1 d-none d-sm-inline">Arquivos Enviados</span>
      </a>
    </li>
  </ul>
  <hr>
</div>