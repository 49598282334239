import { Component, OnInit } from '@angular/core'
import { MsalService } from '@azure/msal-angular'
import { AccountInfo } from '@azure/msal-browser'
import { UsuarioService } from 'src/app/shared/usuario.service'
import { environment } from 'src/environments/environment'

const ambiente = environment.descricaoAmbiente

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	imgPath = '../../assets/images/Logohorizontal.png'
	user: any
	ambiente: any
	profile: AccountInfo[]
	constructor(private msalService: MsalService, private usuarioService: UsuarioService) {}

	ngOnInit(): void {
		this.getProfile()
		this.ambiente = ambiente
	}

	getProfile() {
		this.profile = this.msalService.instance.getAllAccounts()

		if (this.profile) {
			this.usuarioService.getEmailUser(this.profile[0].username).subscribe((res) => {
				this.user = res.usuario
			})
		}
	}

	onLogout() {
		this.msalService.logoutRedirect({
			postLogoutRedirectUri: window.location.origin + '/',
		})
		localStorage.removeItem('token')
	}
}
